import React from "react"

const Header = () => (

  <header>

    <a href='/' title='Serchill'>
      <img src={require("../images/header-icon.svg")} alt='Serchill Logo' />
    </a>

  </header>
)




export default Header
